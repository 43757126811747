@tailwind base;
@tailwind components;
@tailwind utilities;

.swal2-container{
    z-index: 99999999 !important; /* Ensure it's on top */
  }
  
  .swal2-backdrop-show{
    z-index: 99999998 !important; /* Ensure backdrop is behind the popup */
  }